import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const Gallery = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");

  const images = props.data ? props.data.map((d) => d.smallImage) : [];

  const handleWhatsAppOrder = () => {
    if (!phoneNumber) {
      alert("נא להזין מספר טלפון.");
      return;
    }
    const message = `אילן שלום, אשמח שתחזור אליי בהקדם לגבי התקנה/תיקון דלתות ${phoneNumber}`;
    const whatsappUrl = `https://wa.me/972522617505?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <h2>פרוייקטים שביצענו</h2>
        <div className="whatsapp-order">
          <input
            type="text"
            id="phoneNumberInput"
            placeholder="הזן את מספר הטלפון שלך"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{
              marginBottom: "10px",
              padding: "10px",
              width: "100%",
              color: "#0f4b56",
              maxWidth: "300px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <button
            onClick={handleWhatsAppOrder}
            style={{
              marginBottom: "10px",
              padding: "10px 20px",
              backgroundColor: "#25D366",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            שלח הזמנה ב-WhatsApp
          </button>
        </div>
      </div>
      <Swiper
  modules={[Navigation, Pagination, Autoplay]}
  spaceBetween={0}
  slidesPerView={1}
  navigation
  pagination={{ clickable: true }}
  loop={true}
  autoplay={{
    delay: 2000,
    disableOnInteraction: true,
  }}
  className="swiper-container"
  breakpoints={{
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  }}
>
  {props.data
    ? props.data.map((d, i) => (
        <SwiperSlide key={`${d.title}-${i}`}>
          <div className="portfolio-item">
            <img
              src={d.smallImage}
              alt={d.title}
              className="img-fluid"
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                setPhotoIndex(i);
                setIsOpen(true);
              }}
            />
            <h3>{d.title}</h3>
          </div>
        </SwiperSlide>
      ))
    : "טוען..."}
</Swiper>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};
